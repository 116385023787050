import * as React from "react";
import { Input as BaseInput } from "@mui/base/Input";
import { styled } from "@mui/system";

const Input = React.forwardRef(function CustomInput(props, ref) {
  return (
    <BaseInput fullWidth slots={{ input: InputElement }} {...props} ref={ref} />
  );
});

export default function TextInput(props) {
  return (
    <Input
      onChange={props.onChange}
      className={props.className}
      value={props.value}
      placeholder={props.placeholder ?? "Type something…"}
    />
  );
}

const blue = {
  100: "#DAECFF",
  200: "#b6daff",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  900: "#003A75",
};

const InputElement = styled("input")(
  ({ theme }) => `
  width: 100%;
  font-family: 'sans', sans-serif;
  font-size: 0.875rem;
  font-weight: 400;xw
  line-height: 1.5;
  padding: 16px;
  border-radius: 5px;
  color: #fff;
  background: #0B0B0B;
  outline: none;
  border: none;

  &:hover {
    outline: none;
  }

  &:focus {
    border-color: cornflowerblue;

  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`
);
