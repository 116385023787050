import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import { createWeb3Modal } from "@web3modal/wagmi/react";
import { defaultWagmiConfig } from "@web3modal/wagmi/react/config";
import { WagmiProvider } from "wagmi";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { defineChain } from "viem";

const queryClient = new QueryClient();

// 2. Create wagmiConfig
const projectId = "562a49d9c63ea9ae56370f6fc6845988";

export const swamps = defineChain({
  id: 45454,
  name: "Swamps Network",
  nativeCurrency: { name: "SWP", symbol: "SWP", decimals: 18 },
  rpcUrls: {
    default: { http: ["https://swamps.tc.l2aas.com/"] },
  },
  blockExplorers: {
    default: {
      name: "SwampsExplorer",
      url: "https://swamps-explorer.tc.l2aas.com/",
    },
  },
  contracts: {
    multicall3: {
      address: "0x8C065032e716bfaaC9da7995df06CE18541E8c6A",
      blockCreated: 1327677,
    }
  }
});

const metadata = {
  name: "Swamps Bridge",
  description: "Connect Wallet",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [swamps];
export const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});
// 3. Create modal
createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
});

const bridgeContractAddress = "0xecd1d7553afdd6012083845bafdb39172d1bb7f0";
const CoinRankinAPIKey =
  "coinranking9098744bfc3077a468a3931835cf5d5501271b7500aeb360";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </WagmiProvider>
    </ThemeProvider>
  </React.StrictMode>
);

reportWebVitals();
