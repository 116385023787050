// apiService.js
import axios from "axios";

const API_URL = "https://swamp-bridges-api.trustless.computer/api";
const COINRANK_API_KEY =
  "coinranking9098744bfc3077a468a3931835cf5d5501271b7500aeb360";

export const getTokenPrice = async (tokenId) => {
  try {
    const response = await axios.get(
      `https://api.coinranking.com/v2/coin/${tokenId}/price`,
      {
        headers: {
          "x-access-token": COINRANK_API_KEY,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getListTokens = async () => {
  try {
    const response = await axios.get(`${API_URL}/tokens`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getTransactionHistory = async (address) => {
  try {
    console.log(address);
    const response = await axios.get(
      `https://swamp-bridges-api.trustless.computer/api/deposit-withdraw-history?address=${address}`
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getDepositAddress = async (data) => {
  try {
    const response = await axios.post(
      `${API_URL}/generate-deposit-address`,
      data
    );
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const esitimateWithdrawalFees = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/estimate-withdraw-fee`, data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const finalAPI = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/estimate-withdraw-fee`, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
