import React from "react";
import logo from "../assets/logo.png";
import { Button } from "@mui/material";

export function ConnectButton() {
  return <w3m-button />;
}

export default function Header() {
  return (
    <header className="header">
      <div className="group1">
        <div className="branding">
          <img src={logo} width={"30px"} alt="#" className="logo" />
        </div>
        <div className="links" style={{ color: "secondary" }}>
          <li>
            <a className="active" href="#">
              Bridge
            </a>
          </li>
          <li>
            <a href="https://app.swamps.fi" target="_blank">Swap</a>
          </li>
          {/* <li>
            <a href="#">Pool</a>
          </li> */}
        </div>
      </div>
      <div className="group2" style={{ color: "secondary" }}>
        <Button>
          <ConnectButton />
        </Button>
      </div>
    </header>
  );
}
