import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#5EA0FD",
    },
    secondary: {
      main: "#A6A0BB",
    },
    gradient: {
      main: "linear-gradient(to top, #5EA0FD, #0B6FFB)",
    },
  },
  typography: {
    fontFamily: ["sans", "Roboto"].join(","),
  },
});

export default theme;
