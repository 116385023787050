export const btcTokenList = [
  {
    ticker: "BTC",
    img: "/assets/bitcoin.png",
    name: "BTC",
    tokenId: "Qwsogvtv82FCd",
  },
  {
    ticker: "SWP",
    img: "/assets/swp.png",
    name: "SWP",
    tokenId: "xcAaDWVT_",
  },
  {
    ticker: "STAMP",
    img: "/assets/stamp.webp",
    name: "STAMP",
    tokenId: "Nhi6rEsqg",
  },
  {
    ticker: "KEVIN",
    img: "/assets/kevin.png",
    name: "KEVIN",
    tokenId: "DtRJFW8Ta",
  },
  // {
  //   ticker: "LUFFY",
  //   img: "/assets/luffy.png",
  //   name: "LUFFY",
  //   tokenId: "DtRJFW8Ta",
  // },
  // {
  //   ticker: "SPAD",
  //   img: "/assets/spad.png",
  //   name: "SPAD",
  //   tokenId: "DtRJFW8Ta",
  // },
  {
    ticker: "UTXO",
    img: "/assets/utxo.png",
    name: "UTXO",
    tokenId: "DtRJFW8Ta",
  },
];

export const ethTokenList = [
  {
    ticker: "ETH",
    img: "/assets/eth.png",
    name: "Ethereum",
    tokenId: "razxDUgYGNAdQ",
  },
  {
    ticker: "USDC",
    img: "/assets/usdc.png",
    name: "USD Coin",
    tokenId: "aKzUVe4Hh_CON",
  },
  {
    ticker: "USDT",
    img: "/assets/usdt.png",
    name: "Tether USD",
    tokenId: "HIVsRcGKkPFtW",
  },
  {
    ticker: "GSWP",
    img: "/assets/gswp.png",
    name: "Swamps Gov",
    tokenId: "HIVsRcGKkPFtW",
  },
];

export const coinList = [
  {
    ticker: "BTC",
    img: "/assets/bitcoin.png",
    name: "Bitcoin",
  },
  {
    ticker: "ETH",
    img: "/assets/eth.png",
    name: "Ethereum",
  },
  {
    ticker: "Swamps",
    img: "/assets/swamps.png",
    name: "Swamps",
  },
];
