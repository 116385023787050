import { Button } from "@mui/material";

export const PrimaryButton = (props) => {
  return (
    <Button
      onClick={props.onClick ?? null}
      className="primary_button"
      disabled={props.disabled}
      style={{
        padding: "8px 16px",
        borderRadius: "100px",
        ...props.style,
        textTransform: "title",
      }}
      sx={{
        backgroundColor: props.background ?? "#1c1924",
        margin: "5px",
        color: props.color ?? "linear-gradient(to top, #5EA0FD, #0B6FFB)",
        cursor: "pointer",
      }}
    >
      {props.title}
    </Button>
  );
};

export const IconButton = (props) => {
  return (
    <Button
      className="icon_button"
      style={{ padding: "8px 16px", borderRadius: "100px" }}
      sx={{
        background: "#1c1924",
        margin: "5px",
        color: "#fff",
        cursor: "pointer",
      }}
    >
      {props.icon}
    </Button>
  );
};
