import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import "./modal.css";
import { CopyOutlined } from "@ant-design/icons";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { grey } from "@mui/material/colors";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "700px",
  border: "1px solid #948787",
  boxShadow: 24,
  color: "#fff",
  textAlign: "center",
  p: 4,
  borderRadius: "20px",
  outline: "none",
  background: `url("/assets/bg.png")`,
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundSize: "cover",
};

const primaryButtonStyle = {
  borderRadius: "100px",
  padding: "5px 40px",
  background: `linear-gradient(140deg, rgba(11,111,251,1) 0%, rgba(94,160,253,1) 100%)`,
  fontSize: "16px",
  color: "#fff",
};

const secondaryButtonStyle = {
  borderRadius: "100px",
  padding: "5px 40px",
  backgroundColor: "#1D1D1D",
  fontSize: "16px",
  color: "#fff",
};

const tokenBoxSyle = {
  padding: "10px 5px",
  marginTop: "60px",
  marginBottom: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #fff",
  color: "#fff",
  stroke: "#fff",
  maxWidth: "600px",
  fontSize: "17px",
  borderRadius: "10px",
};

const descriptionStyle = {
  color: 'grey',
  fontSize: '14px',
};

export default function DepositAddressModal(props) {
  const [open, setOpen] = React.useState(props.depositAddress);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [alert, setAlert] = React.useState({
    type: "success",
    details: "Address Copied Successfully!",
  });

  const handleClose = () => {
    props.setDepositAddress(null);
    setOpen(false);
  };

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(props.depositAddress)
        .then(() => {})
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      const textarea = document.createElement("textarea");
      textarea.value = props.depositAddress;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
    }
    setAlertOpen(true);
  };

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  return (
    <div>
      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertHandleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={alertHandleClose}
          severity={alert.type}
          variant="filled"
          className="alert"
          sx={{
            width: "100%",
            backgroundColor: "#1d1d1d",
            border: "1px solid #948787",
          }}
        >
          {alert.details}
        </Alert>
      </Snackbar>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="ModalContainer" sx={style}>
          <div
            className="title-container"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Typography className="title" style={{ fontSize: "18px" }}>
              Transfer {props.assetName} to the following {props.fromChain} address
            </Typography>

            <CloseOutlinedIcon
              onClick={handleClose}
              style={{
                position: "absolute",
                right: "0px",
                marginLeft: "100px",
                stroke: "#fff",
                fontWeight: "300",
                strokeWidth: "1px",
                fontSize: "14px",
                cursor: "pointer",
              }}
            />
          </div>
          <Grid container alignContent={"center"} justifyContent={"center"}>
            <Grid item xs={12} style={tokenBoxSyle}>
              <Typography
                onClick={copyToClipboard}
                style={{ cursor: "pointer" }}
              >
                {props.depositAddress}
              </Typography>
              <CopyOutlined
                onClick={copyToClipboard}
                style={{
                  fontSize: "14px",
                  height: "24px",
                  margin: "5px",
                  cursor: "pointer",
                }}
              />
            </Grid>
            {parseFloat(props.swpBalance) === 0 && <div style={descriptionStyle}>
              SWP is our native token.  It is used to perform transactions on Swamps. If you doesn't have SWP, you can buy it on 
              &nbsp;<a style={{color: "lightblue"}} href="https://openstamp.io/market/src20/trading?ticker=SWP" target="_blank">Opentamp</a> or 
              &nbsp;<a style={{color: "lightblue"}} href="https://www.stampscan.xyz/fr/trade/listing?tick=SWP" target="_blank">Stampscan</a>.<br /> 
              Then you can bridge them to Swamps L2 and discover our ecoystem!</div>}
            <Grid
              item
              xs={12}
              alignItems={"center"}
              justifyContent={"center"}
              alignContent={"center"}
              marginTop={5}
              container
            >
              <Grid item xs={4} justifyContent={"space-around"}>
                <Button
                  onClick={handleClose}
                  className="text-title"
                  style={secondaryButtonStyle}
                >
                  Reject
                </Button>
              </Grid>
              <Grid item xs={4}>
                <Button
                  onClick={handleClose}
                  className="text-title"
                  style={primaryButtonStyle}
                >
                  Confirm
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
