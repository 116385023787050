import React from "react";
import "./App.css";
import Bridge from "./components/bridge";
import Header from "./components/header";
import { useConnect, useAccount } from "wagmi";

function App() {
  return (
    <>
      <Header />
      <Bridge />
    </>
  );
}

export default App;
