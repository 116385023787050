import React, { useState, useEffect, useMemo } from "react";
import { isMobile } from "react-device-detect";
import "./bridge.css";
import { Box } from "@mui/material";
import FindInPageOutlinedIcon from "@mui/icons-material/FindInPageOutlined";
import { Grid, Typography } from "@mui/material";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import ImportExportOutlinedIcon from "@mui/icons-material/ImportExportOutlined";
import TextInput from "../mixins/inputs";
import { PrimaryButton } from "../mixins/buttons";
import { Modal, ConfigProvider } from "antd";
import { ethTokenList, btcTokenList, coinList } from "./utils";
import {
  useAccount,
  useReadContracts,
  useWaitForTransactionReceipt,
} from "wagmi";
import axios from "axios";
import { DownOutlined } from "@ant-design/icons";
import "./table.css";
import {
  esitimateWithdrawalFees,
  getDepositAddress,
  getTransactionHistory,
  getTokenPrice,
} from "./apis";
import DepositAddressModal from "./depositAdressModal";
import { useWriteContract } from "wagmi";
import abi from "./abi.json";
import erc20Abi from "./erc20abi.json";
import pairAbi from "./pairabi.json";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { formatEther, formatUnits, parseEther, parseUnits } from "viem";
import { getBalance } from "viem/actions";
import { config } from "..";
import bridgeImgH from "../assets/bridge/bridge-col.png";
import bridgeImgV from "../assets/bridge/bridge-row.png";

export const MinBridgeAmountFromSwamps = {
  SWP: 10000,
  STAMP: 2000,
  KEVIN: 5000,
  USDT: 25,
  USDC: 25,
  ETH: 0.0081,
  BTC: 0.0001,
  GSWP: 1250,
  LUFFY: 5000,
  SPAD: 10000,
  UTXO: 5000,
};

export const bridgingEstimate = {
  bitcoin: ["~30 mins", "~11 mins"],
  src20: ["~30 mins", "~21 mins"],
  ethereum:  ["~10 mins", "~3 mins"]
}

function Bridge(props) {
  const { address, isConnected } = useAccount();
  // const chainId = useChainId()
  const [tokenList, setTokenList] = useState(btcTokenList);
  const [fromCoin, setFromCoin] = useState(coinList[0]);
  const [toCoin, setToCoin] = useState(coinList[2]);
  const [isOpen, setIsOpen] = useState(false);
  const [assetModalIsOpen, setAssetModalIsOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(tokenList[1]);
  const [changeToken, setChangeToken] = useState(1);
  const [depositAddress, setDepositAddress] = useState(null);
  const [minWithdrawAmount, setMinWithdrawAmount] = useState(0.0);
  const [withdrawFee, setWithdrawFee] = useState(0);
  const [destinateAddress, setDestinateAddress] = useState(null);
  const [userAmount, setUserAmount] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [bridgeButtonLoading, setBridgeButtonLoading] = useState(false);
  const [currentAsset, setCurrentAsset] = useState(tokenList[1]);
  const [alert, setAlert] = useState({
    type: "success",
    details: "",
  });

  const [bridgeMapModalOpen, setBridgeMapModalOpen] = useState(false);
  const [tableModalOpen, setTableModalOpen] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState(null);
  const { open } = useWeb3Modal();
  const {
    data: hash,
    error: transactionError,
    writeContract,
  } = useWriteContract();
  const { isLoading: isConfirming, isSuccess: isConfirmed } =
    useWaitForTransactionReceipt({
      hash,
    });

  const { data: contractResult, refetch: refetchAllowance } = useReadContracts(
    {
      contracts: [
        {
          address: selectedAsset.tcTokenID,
          abi: erc20Abi,
          functionName: "allowance",
          args: [address, "0xecd1d7553afdD6012083845baFdb39172d1bB7f0"],
        },
        {
          address: selectedAsset.tcTokenID,
          abi: erc20Abi,
          functionName: "balanceOf",
          args: [address],
        },
        {
          address: selectedAsset.tcTokenID,
          abi: erc20Abi,
          functionName: "totalSupply",
          args: [],
        },
      ],
    },
    [selectedAsset, fromCoin, toCoin, isConfirmed]
  );

  useEffect(() => {
    refetchAllowance();
    if (isConfirmed) {
      setBridgeButtonLoading(false);
      setAlert({ type: "success", details: "Transaction Success!" });
      setAlertOpen(true);
    }
    if (isConfirming) {
      setBridgeButtonLoading(true);
      setAlert({ type: "success", details: "Transaction Submitted!" });
      setAlertOpen(true);
    }
  }, [isConfirmed, isConfirming]);

  useEffect(() => {
    if (transactionError !== null) {
      setAlert({
        type: "error",
        details: `Error: ${
          transactionError.shortMessage || transactionError.message
        }`,
      });
      setAlertOpen(true);
      setBridgeButtonLoading(false);
    }
  }, [transactionError, hash]);

  const allownace = useMemo(() => {
    if (!contractResult) return 0;
    if (contractResult[0].status !== "success") return 0;
    // const allowance = formatUnits(contractResult[0].result, selectedAsset.decimals) // decimals is 18 on swamps but got 6 from backend
    return parseFloat(formatUnits(contractResult[0].result, 18));
  }, [contractResult]);

  const balance = useMemo(() => {
    if (!contractResult) return 0;
    if (contractResult[1].status !== "success") return 0;
    // const allowance = formatUnits(contractResult[0].result, selectedAsset.decimals) // decimals is 18 on swamps but got 6 from backend
    return parseFloat(formatUnits(contractResult[1].result, 18));
  }, [contractResult]);

  const crossChainAmount = useMemo(() => {
    if (!contractResult) return 0;
    if (contractResult[2].status !== "success") return 0;
    // const allowance = formatUnits(contractResult[0].result, selectedAsset.decimals) // decimals is 18 on swamps but got 6 from backend
    return parseFloat(formatUnits(contractResult[2].result, 18));
  }, [contractResult]);


  const { data: priceResultFromContract, refetch: refetchPrice } = useReadContracts(
    {
      contracts: [
        {
          address: "0x53F79ff3677f1133738da918B8eDc5B7ABA9F16f",
          abi: pairAbi,
          functionName: "getReserves",
          chainId: 45454,
          args: [],
        },
        {
          address: "0x2fF2D14c1808E269D7e5407b511a8e09d0498219",
          abi: pairAbi,
          functionName: "getReserves",
          chainId: 45454,
          args: [],
        },
        {
          address: "0xb663e53b22d3139eb60d0c043268f1c4c6e38236",
          abi: pairAbi,
          functionName: "getReserves",
          chainId: 45454,
          args: [],
        },
        {
          address: "0xb11fd6cac4667298611d66d65b7cebffff76f423",
          abi: pairAbi,
          functionName: "getReserves",
          chainId: 45454,
          args: [],
        },
        {
          address: "0x281939f27007f2cf0106dd5fa22e1be9dacab7df",
          abi: pairAbi,
          functionName: "getReserves",
          chainId: 45454,
          args: [],
        },
        {
          address: "0x4932e9038b9090f91a81e49ce08796c9a74c41c4",
          abi: pairAbi,
          functionName: "getReserves",
          chainId: 45454,
          args: [],
        },
      ],
    },
    [selectedAsset, fromCoin, toCoin, isConfirmed]
  );
  const assetsPrice = useMemo(() => {
    if (!priceResultFromContract) return undefined;
    if (priceResultFromContract[0].status !== "success") return undefined;
    let reserve0 = parseFloat(formatUnits(priceResultFromContract[0].result[0], 18));
    let reserve1 = parseFloat(formatUnits(priceResultFromContract[0].result[1], 18));
    const gswpPrice = reserve1 / reserve0;
    reserve0 = parseFloat(formatUnits(priceResultFromContract[1].result[0], 18));
    reserve1 = parseFloat(formatUnits(priceResultFromContract[1].result[1], 18));
    const swpPrice = reserve0 / reserve1;
    reserve0 = parseFloat(formatUnits(priceResultFromContract[2].result[0], 18));
    reserve1 = parseFloat(formatUnits(priceResultFromContract[2].result[1], 18));
    const ethPrice = reserve0 / reserve1;
    reserve0 = parseFloat(formatUnits(priceResultFromContract[3].result[0], 18));
    reserve1 = parseFloat(formatUnits(priceResultFromContract[3].result[1], 18));
    const btcPrice = reserve1 / reserve0;
    reserve0 = parseFloat(formatUnits(priceResultFromContract[4].result[0], 18));
    reserve1 = parseFloat(formatUnits(priceResultFromContract[4].result[1], 18));
    const stampPrice = reserve0 / reserve1 * btcPrice;
    reserve0 = parseFloat(formatUnits(priceResultFromContract[5].result[0], 18));
    reserve1 = parseFloat(formatUnits(priceResultFromContract[5].result[1], 18));
    const kevinPrice = reserve0 / reserve1 * btcPrice;
    return {
      "usdt": 1,
      "usdc": 1,
      "gswp": gswpPrice,
      "swp": swpPrice,
      "eth": ethPrice,
      "btc": btcPrice,
      "kevin": kevinPrice,
      "stamp": stampPrice,
      "luffy": 0,
      "spad": 0,
      "utxo": 0,
    }
  }, [priceResultFromContract]);

  const [swpBalance, setSwpBalance] = useState(0);
  useEffect(() => {
    const getSwpBalance = async () => {
      const _swpBalance = await getBalance(config.getClient(), {
        address: address,
      });
      setSwpBalance(parseFloat(formatEther(_swpBalance)));
    };
    if (address) getSwpBalance();
  }, [address]);

  const [swpBalanceInBridge, setSwpBalanceInBridge] = useState(0);
  useEffect(() => {
    const getSwpBalance = async () => {
      const _swpBalance = await getBalance(config.getClient(), {
        address: "0xecd1d7553afdD6012083845baFdb39172d1bB7f0",
      });
      setSwpBalanceInBridge(parseFloat(formatEther(_swpBalance)));
    };
    getSwpBalance();
  }, []);

  const checkCanBridge = () => {
    let _tokenBalance = balance;
    if (
      fromCoin.ticker === "Swamps" &&
      toCoin.ticker === "BTC" &&
      selectedAsset.ticker === "SWP"
    ) {
      _tokenBalance = swpBalance;
    }
    if (_tokenBalance < parseFloat(userAmount)) {
      setAlert({ type: "error", details: "Insufficient Balance" });
      setAlertOpen(true);
      setBridgeButtonLoading(false);
      return false;
    }
    if (!userAmount || userAmount === null || parseFloat(userAmount) <= 0) {
      setAlert({ type: "error", details: "Invalid Bridge Amount" });
      setAlertOpen(true);
      setBridgeButtonLoading(false);
      return false;
    }
    // if (minBridgeAmount + withdrawFee > parseFloat(userAmount)) {
    if (minBridgeAmount > parseFloat(userAmount)) {
      setAlert({ type: "error", details: "Not meeting the minimum amount." });
      setAlertOpen(true);
      setBridgeButtonLoading(false);
      return false;
    }
    if (
      fromCoin.ticker === "Swamps" &&
      toCoin.ticker === "BTC" &&
      (!destinateAddress || destinateAddress.length < 24)
    ) {
      setAlert({ type: "error", details: "Invalid destination address." });
      setAlertOpen(true);
      setBridgeButtonLoading(false);
      return false;
    }
    return true;
  };

  const shouldApprove = useMemo(() => {
    if (toCoin.ticker === "Swamps") {
      return false;
    }
    if (
      fromCoin.ticker === "Swamps" &&
      toCoin.ticker === "BTC" &&
      selectedAsset.ticker === "SWP"
    ) {
      return false;
    }
    return allownace < parseFloat(userAmount);
  }, [userAmount, allownace, fromCoin, toCoin, selectedAsset]);

  const alertHandleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const updateDepositeAddress = (value) => {
    setDepositAddress(value);
  };

  const updateDestinateAddress = (event) => {
    setDestinateAddress(event.target.value);
  };

  const updateAmount = (event) => {
    setUserAmount(event.target.value);
  };

  function switchTokens() {
    const one = fromCoin;
    const two = toCoin;
    setFromCoin(two);
    setToCoin(one);
  }

  function openModal(asset) {
    setChangeToken(asset);
    setIsOpen(true);
  }

  function assetOpenModal(asset) {
    setChangeToken(asset);
    setAssetModalIsOpen(true);
  }

  function modifyCoin(i) {
    let selectedCoin = coinList[i];
    if (
      changeToken === 1 &&
      (selectedCoin.ticker === "ETH" || selectedCoin.ticker === "BTC")
    ) {
      setFromCoin(selectedCoin);
      setToCoin(coinList.filter((item) => item.ticker === "Swamps")[0]);
    } else if (changeToken === 1 && selectedCoin.ticker === "Swamps") {
      setFromCoin(selectedCoin);
      if (toCoin.ticker === "Swamps") {
        setToCoin(coinList.filter((item) => item.ticker === "BTC")[0]);
      }
    } else if (changeToken === 2 && selectedCoin.ticker === "Swamps") {
      setToCoin(selectedCoin);
      if (fromCoin.ticker === "Swamps") {
        setFromCoin(coinList.filter((item) => item.ticker === "BTC")[0]);
      }
    } else if (
      changeToken === 2 &&
      (selectedCoin.ticker === "ETH" || selectedCoin.ticker === "BTC")
    ) {
      setToCoin(selectedCoin);
      setFromCoin(coinList.filter((item) => item.ticker === "Swamps")[0]);
    }
    setIsOpen(false);
  }

  function modifyToken(i) {
    setCurrentAsset(tokenList[i]);
    setAssetModalIsOpen(false);
  }

  useEffect(() => {
    if (fromCoin.ticker === "ETH" || toCoin.ticker === "ETH") {
      setTokenList(ethTokenList);
    } else if (fromCoin.ticker === "BTC" || toCoin.ticker === "BTC") {
      setTokenList(btcTokenList);
    }
  }, [fromCoin, toCoin]);

  useEffect(() => {
    setCurrentAsset(tokenList[1]);
  }, [tokenList]);
  
  useEffect(() => {
    setSelectedAsset({ ...currentAsset });
    const fetchAssetTokenPrices = async () => {
      try {
        const res = await axios.get(
          "https://swamp-bridges-api.trustless.computer/api/tokens"
        );

        let tokenDetails = res.data.data.filter(
          (item) =>
            item.symbol.toLowerCase() === currentAsset.ticker.toLowerCase()
        )[0];

        let price = 0;
        try {
          const priceResult = await getTokenPrice(currentAsset.tokenId);
          price = priceResult.data.price;
        } catch (error) {}

        if (tokenDetails) {
          setSelectedAsset({
            ...currentAsset,
            price: price,
            tcTokenID: tokenDetails.tcTokenID,
            decimals: tokenDetails.decimals,
          });

          if (
            fromCoin.ticker === "Swamps" &&
            (toCoin.ticker === "ETH" || toCoin.ticker === "BTC")
          ) {
            console.log("Running esitimateWithdrawalFees API");
            try {
              const fetchData = async () => {
                const data = {
                  tcAddress: address,
                  tcTokenID: tokenDetails.tcTokenID,
                  decimals: tokenDetails.decimals,
                };
                const result = await esitimateWithdrawalFees(data);
                setMinWithdrawAmount(
                  parseFloat(
                    formatUnits(
                      parseFloat(result.minWithdrawAmount).toString(),
                      18
                    )
                  )
                );
                setWithdrawFee(
                  parseFloat(
                    formatUnits(parseFloat(result.withdrawFee).toString(), 18)
                  )
                );
              };
              setBridgeButtonLoading(true);
              fetchData();
              setBridgeButtonLoading(false);
            } catch (error) {
              console.log(error);
            }
          } else {
            setMinWithdrawAmount(0);
            setWithdrawFee(0);
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchAssetTokenPrices();
  }, [currentAsset, fromCoin, toCoin, address]);

  const showTransactionHistory = async () => {
    if (isConnected) {
      try {
        const result = await getTransactionHistory(address);
        if (result.data) {
          let _ret = result.data.slice();
          _ret = _ret.map((item) => {
            let newItem = item;
            newItem.fromToNetworkInfo = newItem.fromToNetworkInfo
              .toString()
              .replace("NOS", "SWAMPS");
            newItem.fromToNetworkInfo = newItem.fromToNetworkInfo
              .toString()
              .replace("STAMPS", "BTC");
            return newItem;
          });
          _ret.sort(
            (prev, next) =>
              new Date(next.created_at).getTime() -
              new Date(prev.created_at).getTime()
          );
          setTransactionHistory(_ret);
        }
        setTableModalOpen(true);
      } catch (error) {
        console.log(error);
      }
    } else {
      open();
    }
  };

  async function bridgeFunc() {
    if (isConnected && selectedAsset) {
      setBridgeButtonLoading(true);
      if (
        (fromCoin.ticker === "BTC" && toCoin.ticker === "Swamps") ||
        (fromCoin.ticker === "ETH" && toCoin.ticker === "Swamps")
      ) {
        try {
          const data = {
            tcAddress: address,
            tcTokenID: selectedAsset.tcTokenID,
          };
          console.log(data);
          const result = await getDepositAddress(data);
          setDepositAddress(result.depositAddress);
        } catch (error) {
          console.log("Error fetching data:", error);
        }
        setBridgeButtonLoading(false);
      } else if (fromCoin.ticker === "Swamps" && toCoin.ticker === "BTC") {
        if (selectedAsset.ticker === "SWP") {
          if (!checkCanBridge()) return;
          try {
            writeContract({
              address: "0xecd1d7553afdD6012083845baFdb39172d1bB7f0",
              abi,
              functionName: "bridgeToken",
              args: [destinateAddress, 6666],
              value: parseEther(userAmount.toString()),
            });
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        } else {
          if (shouldApprove) {
            try {
              writeContract({
                address: selectedAsset.tcTokenID,
                abi: erc20Abi,
                functionName: "approve",
                args: [
                  "0xecd1d7553afdD6012083845baFdb39172d1bB7f0",
                  // parseUnits(userAmount, selectedAsset.decimals),  // decimals is 18 on swamps but got 6 from backend
                  parseUnits(userAmount.toString(), 18),
                ],
              });
            } catch (error) {
              console.log(error);
            }
            return;
          }
          try {
            if (!checkCanBridge()) return;
            writeContract({
              address: "0xecd1d7553afdD6012083845baFdb39172d1bB7f0",
              abi,
              functionName: "bridgeToken",
              args: [
                selectedAsset.tcTokenID,
                parseUnits(userAmount.toString(), 18),
                destinateAddress,
                6666,
              ],
            });
            setUserAmount(0);
            setDestinateAddress("");
            setAlert({ type: "success", details: "Bridged Successfully!" });
            setAlertOpen(true);
          } catch (error) {
            console.error("Error fetching data:", error);
          }
        }
      } else if (fromCoin.ticker === "Swamps" && toCoin.ticker === "ETH") {
        try {
          if (shouldApprove) {
            try {
              writeContract({
                address: selectedAsset.tcTokenID,
                abi: erc20Abi,
                functionName: "approve",
                args: [
                  "0xecd1d7553afdD6012083845baFdb39172d1bB7f0",
                  // parseUnits(userAmount, selectedAsset.decimals),  // decimals is 18 on swamps but got 6 from backend
                  parseUnits(userAmount.toString(), 18),
                ],
              });
            } catch (error) {
              console.log(error);
            }
            return;
          }
          if (!checkCanBridge()) return;
          writeContract({
            address: "0xecd1d7553afdD6012083845baFdb39172d1bB7f0",
            abi,
            functionName: "bridgeToken",
            args: [
              selectedAsset.tcTokenID,
              // parseUnits(userAmount, selectedAsset.decimals),  // decimals is 18 on swamps but got 6 from backend
              parseUnits(userAmount.toString(), 18),
              address,
              1,
            ],
          });
          setUserAmount(0);
          setAlertOpen(true);
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } else {
      open();
    }
  }

  function getDisplayTransaction(tx, delta = 4) {
    if (!!tx) {
      return (
        tx.substring(0, delta) +
        "..." +
        tx.substring(tx.length - delta, tx.length)
      );
    } else {
      return "--";
    }
  }

  const getDisplayNumber = (num) => {
    return parseFloat(parseFloat(num).toFixed(5));
  };

  function getScanLink(hex, type, chainName) {
    if (!hex) return;
    if (chainName.trim() === "BTC") {
      return type === "transaction"
        ? `https://mempool.space/tx/${hex}`
        : `https://mempool.space/address/${hex}`;
    } else if (chainName.trim() === "SWAMPS") {
      return type === "transaction"
        ? `https://swamps-explorer.tc.l2aas.com/tx/${hex}`
        : `https://swamps-explorer.tc.l2aas.com/address/${hex}`;
    } else if (chainName.trim() === "ETH") {
      return type === "transaction"
        ? `https://etherscan.io/tx/${hex}`
        : `https://etherscan.io/address/${hex}`;
    }
    return;
  }

  const bridgingEstimateTime = useMemo(() => {
    const isDeposit = toCoin.ticker === "Swamps"
    if(selectedAsset.ticker === "ETH" || 
      selectedAsset.ticker === "USDT" || 
      selectedAsset.ticker === "USDC" || 
      selectedAsset.ticker === "GSWP"
    ) {
      return bridgingEstimate["ethereum"][isDeposit? 0 : 1]
    } else if(selectedAsset.ticker === "SWP" || 
      selectedAsset.ticker === "STAMP" || 
      selectedAsset.ticker === "KEVIN" || 
      selectedAsset.ticker === "LUFFY" || 
      selectedAsset.ticker === "SPAD" || 
      selectedAsset.ticker === "UTXO"
    ) {
      return bridgingEstimate["src20"][isDeposit? 0: 1]
    } else {
      return bridgingEstimate["bitcoin"][isDeposit? 0: 1]
    }
  }, [toCoin, selectedAsset])
  const minBridgeAmount = useMemo(() => {
    // const isSrc20 = selectedAsset.ticker === "BTC" ||
    //                   selectedAsset.ticker === "ETH" ||
    //                   selectedAsset.ticker === "SWP" ||
    //                   selectedAsset.ticker === "USDT" ||
    //                   selectedAsset.ticker === "USDC" ||
    //                   selectedAsset.ticker === "GSWP"
    return fromCoin.ticker === "Swamps"
      ? MinBridgeAmountFromSwamps[selectedAsset.ticker]
      : minWithdrawAmount;
  }, [minWithdrawAmount, selectedAsset]);

  return (
    <div className="main-container">
      {transactionError && (
        <div>
          Error: {transactionError.shortMessage || transactionError.message}
        </div>
      )}

      <Snackbar
        open={alertOpen}
        autoHideDuration={6000}
        onClose={alertHandleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={alertHandleClose}
          severity={alert.type}
          variant="filled"
          className="alert"
          sx={{
            width: "100%",
            backgroundColor: "#1d1d1d",
            border: "1px solid #948787",
          }}
        >
          {alert.details}
        </Alert>
      </Snackbar>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#1d1d1d",
              footerBg: "#1d1d1d",
              headerBg: "#1d1d1d",
              titleColor: "#fff",
              titleLineHeight: 2,
              colorText: "#fff",
              colorIcon: "#fff",
            },
          },
        }}
      >
        <Modal
          open={isOpen}
          footer={null}
          onCancel={() => setIsOpen(false)}
          title="Select a token"
          contentBg="#1d1d1d"
        >
          <div className="modalContent">
            {coinList?.map((e, i) => {
              return (
                <div
                  className="tokenChoice"
                  key={i}
                  onClick={() => modifyCoin(i)}
                >
                  <img src={e.img} alt={e.ticker} className="tokenLogo" />
                  <div className="tokenChoiceNames">
                    <div className="tokenName">{e.name}</div>
                    <div className="tokenTicker">{e.ticker}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
      </ConfigProvider>

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#1d1d1d",
              footerBg: "#1d1d1d",
              headerBg: "#1d1d1d",
              titleColor: "#fff",
              titleLineHeight: 2,
              colorText: "#fff",
              colorIcon: "#fff",
            },
          },
        }}
      >
        <Modal
          open={assetModalIsOpen}
          footer={null}
          onCancel={() => setAssetModalIsOpen(false)}
          title="Select Assets"
          contentBg="#1d1d1d"
        >
          <div className="asset_modal">
            {tokenList?.map((e, i) => {
              return (
                <div
                  className="tokenChoice"
                  key={i}
                  onClick={() => modifyToken(i)}
                >
                  <img src={e.img} alt={e.ticker} className="tokenLogo" />
                  <div className="tokenChoiceNames">
                    <div className="tokenName">{e.name}</div>
                    <div className="tokenTicker">{e.ticker}</div>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal>
      </ConfigProvider>

      {/* Table Model */}
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#1d1d1d",
              footerBg: "#1d1d1d",
              headerBg: "#1d1d1d",
              titleColor: "#fff",
              titleLineHeight: 2,
              colorText: "#fff",
              colorIcon: "#fff",
            },
          },
        }}
      >
        <Modal
          open={tableModalOpen}
          footer={null}
          onCancel={() => setTableModalOpen(false)}
          title="Transaction History"
          contentBg="#1d1d1d"
          maxWidth={"95%"}
          width={"auto"}
          margin={"20px"}
        >
          {transactionHistory ? (
            <table className="table-container">
              <thead>
                <tr>
                  <th scope="col">Direction</th>
                  <th scope="col">From</th>
                  <th scope="col">Destination</th>
                  <th scope="col">Amount</th>
                  <th scope="col">Fee</th>
                  <th scope="col">Symbol</th>
                  <th scope="col">Status</th>
                  <th scope="col">Time</th>
                  <th scope="col">Deposit Tx</th>
                  <th scope="col">Withdraw Tx</th>
                </tr>
              </thead>

              <tbody>
                {transactionHistory.map((transaction, index) => (
                  <tr key={index} scope="row">
                    <td>{transaction.fromToNetworkInfo}</td>
                    <td>
                      <a
                        href={getScanLink(
                          transaction.fromAddress,
                          "address",
                          transaction.fromToNetworkInfo.split("->").at(0)
                        )}
                        target="_blank"
                      >
                        {getDisplayTransaction(transaction.fromAddress)}
                      </a>
                    </td>
                    <td>
                      <a
                        href={getScanLink(
                          transaction.withdrawWallet,
                          "address",
                          transaction.fromToNetworkInfo.split("->").at(1)
                        )}
                        target="_blank"
                      >
                        {getDisplayTransaction(transaction.withdrawWallet)}
                      </a>
                    </td>
                    <td>
                      {getDisplayNumber(formatUnits(transaction.amountTc, 18))}
                    </td>
                    <td>
                      {transaction.fee
                        ? getDisplayNumber(formatUnits(transaction.fee, 18))
                        : 0}
                    </td>
                    <td>{transaction.tcTokenInfo.symbol}</td>
                    <td>{transaction.statusStr}</td>
                    <td>{transaction.created_at}</td>
                    <td>
                      <a
                        href={getScanLink(
                          transaction.txBurnedWithdraw,
                          "transaction",
                          transaction.fromToNetworkInfo.split("->").at(0)
                        )}
                        target="_blank"
                      >
                        {getDisplayTransaction(transaction.txBurnedWithdraw)}
                      </a>
                    </td>
                    <td>
                      <a
                        href={getScanLink(
                          transaction.txProcessWithdraw,
                          "transaction",
                          transaction.fromToNetworkInfo.split("->").at(1)
                        )}
                        target="_blank"
                      >
                        {getDisplayTransaction(transaction.txProcessWithdraw)}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "50px 0px",
              }}
            >
              No Transaction History
            </div>
          )}
        </Modal>
      </ConfigProvider>

      {depositAddress ? (
        <DepositAddressModal
          depositAddress={depositAddress}
          assetName={selectedAsset.ticker}
          fromChain={fromCoin.name}
          swpBalance={swpBalance}
          setDepositAddress={updateDepositeAddress}
        />
      ) : null}

      <ConfigProvider
        theme={{
          components: {
            Modal: {
              contentBg: "#1d1d1d",
              footerBg: "#1d1d1d",
              headerBg: "#1d1d1d",
              titleColor: "#fff",
              titleLineHeight: 2,
              colorText: "#fff",
              colorIcon: "#fff",
            },
          },
        }}
      >
        <Modal
          open={bridgeMapModalOpen}
          footer={null}
          onCancel={() => setBridgeMapModalOpen(false)}
          title="How it works"
          contentBg="#1d1d1d"
          maxWidth={"80px"}
          width={"auto"}
          margin={"50px"}
        >
          <img
            lt="bridge"
            src={isMobile ? bridgeImgV : bridgeImgH}
            width="100%"
          />
        </Modal>
      </ConfigProvider>
      <Grid
        container
        xs={12}
        rowSpacing={2}
        justifyContent={"space-evenly"}
        alignItems={"center"}
        maxWidth="lg"
        padding={{
          xs: "10px 20px 20px 20px",
          md: "10px 50px 50px 50px",
          lg: "10px 100px 100px 100px",
        }}
        sx={{
          color: "#fff",
          width: "100%",
        }}
      >
        <Grid
          item
          xs={12}
          md={6}
          lg={5}
          container
          sx={{ marginTop: "50px" }}
          className="bridge__container"
          justifyContent={"space-between"}
        >
          <Grid
            container
            alignContent="center"
            justifyContent="center"
            style={{ marginBottom: "72px" }}
            className="bridge__container__heading"
          >
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "24px", width: "80%" }}>
                Bridge
              </Typography>
              <Typography
                sx={{ fontSize: "14px", color: "rgba(255, 255, 255, 0.7)" }}
              >
                Bridge tokens in an instant
              </Typography>
            </Grid>
            <Grid
              item
              justifyContent="end"
              alignContent="end"
              alignItems={"center"}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
              xs={6}
            >
              <Box>
                <FindInPageOutlinedIcon
                  onClick={showTransactionHistory}
                  sx={{ height: "24px", cursor: "pointer" }}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="swap__from"
            sx={{ height: "63px" }}
          >
            <Grid item xs={6}>
              <Typography sx={{ fontSize: "14px", width: "100%" }}>
                From
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              width="100%"
              alignItems="end"
              style={{ display: "flex", justifyContent: "end" }}
            >
              <div className="inputs">
                <div className="assetOne" onClick={() => openModal(1)}>
                  <img
                    src={fromCoin.img}
                    alt="assetOneLogo"
                    className="assetLogo"
                  />
                  {fromCoin.ticker}
                  <DownOutlined />
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "15px",
            }}
          >
            <div className="inputs">
              <div className="switchButton" onClick={switchTokens}>
                <ImportExportOutlinedIcon
                  className="switchArrow"
                  height="16px"
                />
              </div>
            </div>
          </Grid>
          <Grid
            container
            spacing={5}
            alignItems="center"
            justifyContent={"space-between"}
            sx={{ height: "63px", marginBottom: "27px" }}
          >
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                justifyContent={{ lg: "space-between", md: "center" }}
                className="swap__from"
              >
                <Grid
                  item
                  xs={12}
                  lg={5}
                  md={12}
                  textAlign={{ xs: "center", md: "center", lg: "left" }}
                >
                  <Typography sx={{ fontSize: "14px", width: "100%" }}>
                    To
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={5}
                  alignItems={{ xs: "center", lg: "end", md: "center" }}
                  justifyContent={{ xs: "center", md: "center", lg: "end" }}
                  style={{ display: "flex" }}
                >
                  <div className="inputs">
                    <div className="assetTwo" onClick={() => openModal(2)}>
                      <img
                        src={toCoin.img}
                        alt="assetOneLogo"
                        className="assetLogo"
                      />
                      {toCoin.ticker}
                      <DownOutlined />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid
                container
                alignItems="center"
                justifyContent={{
                  xs: "center",
                  lg: "space-between",
                  md: "center",
                }}
                className="swap__from"
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={5}
                  textAlign={{ xs: "center", md: "center", lg: "left" }}
                >
                  <Typography sx={{ fontSize: "14px", width: "100%" }}>
                    Asset
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={5}
                  alignItems={{ xs: "center", lg: "end", md: "center" }}
                  justifyContent={{ xs: "center", md: "center", lg: "end" }}
                  style={{ display: "flex" }}
                >
                  <div className="inputs">
                    <div
                      className="assetThree"
                      onClick={() => assetOpenModal(3)}
                    >
                      <img
                        src={currentAsset.img}
                        alt="assetOneLogo"
                        className="assetLogo"
                      />
                      {currentAsset.ticker}
                      <DownOutlined />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {isConnected && toCoin.ticker === "BTC" ? (
            <Grid
              container
              alignItems="center"
              justifyContent={"center"}
              sx={{ marginTop: "27px", width: "100%" }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  marginTop: "27px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    direction: "rtl",
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  Balance:{" "}
                  {getDisplayNumber(
                    selectedAsset.ticker === "SWP" ? swpBalance : balance
                  )}
                </Typography>
                <TextInput
                  className="bridge__input"
                  placeholder={"Bridge Amount"}
                  value={userAmount}
                  onChange={updateAmount}
                />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  marginTop: "30px",
                }}
              >
                <TextInput
                  className="bridge__input"
                  placeholder={"Your Bitcoin Receiver Address"}
                  value={destinateAddress}
                  onChange={updateDestinateAddress}
                />
              </Box>
            </Grid>
          ) : (
            ""
          )}

          {isConnected && toCoin.ticker === "ETH" ? (
            <Grid
              container
              alignItems="center"
              justifyContent={"center"}
              sx={{ marginTop: "27px", width: "100%" }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "100%",
                  marginTop: "27px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    direction: "rtl",
                    color: "rgba(255, 255, 255, 0.7)",
                  }}
                >
                  Balance: {getDisplayNumber(balance)}
                </Typography>
                <TextInput
                  className="bridge__input"
                  placeholder={"Bridge Amount"}
                  value={userAmount}
                  onChange={updateAmount}
                />
              </Box>
            </Grid>
          ) : (
            ""
          )}

          <Grid
            container
            sx={{ height: "36px" }}
            marginTop={{ md: "50px", lg: "30px", xs: "40px" }}
          >
            <PrimaryButton
              onClick={bridgeFunc}
              disabled={bridgeButtonLoading}
              style={{
                width: "100%",
                borderRadius: "50px",
                padding: "8px",
                fontSize: "16px",
                margin: "0",
              }}
              title={
                bridgeButtonLoading
                  ? "Please Wait..."
                  : isConnected
                  ? shouldApprove
                    ? "Approve"
                    : "Bridge"
                  : "Unlock Wallet"
              }
              background="#1685EC"
              color="white"
            />
          </Grid>
          <Grid
            item
            xs={12}
            container
            alignContent={"center"}
            paddingY={{ lg: "20px", md: "15px", xs: "10px" }}
            paddingX={{ lg: "32px", md: "10px", xs: "10px" }}
          >
            <Grid item xs={6}>
              <Typography
                sx={{ fontSize: "14px", color: "rgba(255, 255, 255, 0.7)" }}
              >
                {/* Bridge fees : {getDisplayNumber(withdrawFee)} */}
                Estimate:{" "}
                {bridgingEstimateTime}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "end" }}
            >
              <Typography
                sx={{ fontSize: "14px", color: "rgba(255, 255, 255, 0.7)" }}
              >
                {/* Min amount : {getDisplayNumber(minWithdrawAmount)} */}
                Min amount : {getDisplayNumber(minBridgeAmount)}
              </Typography>
            </Grid>
          </Grid>

          <div className="circle1"></div>
          <div className="circle2"></div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={5}
          md={6}
          sx={{ padding: "10px " }}
          className="details"
        >
          <Grid container>
            <Grid item xs={12} sx={{ padding: "10px" }}>
              <Typography
                sx={{
                  lineHeight: "126%",
                  letterSpacing: "0",
                }}
                fontSize={{ md: "30px", lg: "36px", xs: "25px" }}
                padding={{ xs: "10px 0" }}
              >
                Token Information
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            style={{
              padding: "30px",
              backgroundColor: "rgba(41, 41, 41, 0.56",
              borderRadius: "16px",
            }}
          >
            <Grid item xs={12} md={6} lg={6} textAlign={{ xs: "center" }}>
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                justifyContent={{ xs: "center", md: "start" }}
              >
                <img
                  src={selectedAsset.img ? selectedAsset.img : "null"}
                  height={"35px"}
                  alt=""
                />
                <Typography
                  sx={{
                    fontSize: "30px",
                    lineHeight: "125%",
                    letterSpacing: "0",
                    padding: "0 10px",
                  }}
                >
                  {selectedAsset.ticker}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6} lg={6}>
              <Typography
                sx={{
                  fontSize: "25px",
                  lineHeight: "125%",
                  letterSpacing: "0",
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                justifyContent={{ xs: "center", md: "end" }}
                padding={{ xs: "5px 0", md: "0" }}
              >
                {assetsPrice? getDisplayNumber(assetsPrice[selectedAsset.ticker.toLowerCase()]) : 0.0} $
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} sx={{ padding: "50px 0" }}>
              <Typography
                sx={{
                  fontSize: "17px",
                  lineHeight: "72%",
                  letterSpacing: "0",
                }}
              >
                Existing cross-chain amount
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ padding: "0 15px" }}>
              <Typography
                sx={{
                  fontSize: "40px",
                  lineHeight: "72%",
                  letterSpacing: "0",
                }}
              >
                {getDisplayNumber(
                  selectedAsset.ticker === "SWP"
                    ? 1_000_000_000 - swpBalanceInBridge
                    : crossChainAmount
                )}
              </Typography>
            </Grid>
          </Grid>

          {/* <Grid
            padding={"40px 0"}
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            style={{ fontSize: "17px", lineHeight: "72px" }}
          >
            <Grid item xs={6}>
              <Typography
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
                lineHeight={{ md: "50px", lg: "72px", xs: "30px" }}
              >
                Bridge contract address
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                lineHeight={{ md: "50px", lg: "72px", xs: "30px" }}
                justifyContent={{ xs: "end", md: "center", lg: "end" }}
              >
                bc1q8...8zp0v
              </Typography>
            </Grid>
          </Grid> */}

          <Grid
            margin="40px 0"
            container
            alignItems={"center"}
            justifyContent={"space-between"}
            style={{
              padding: "30px",
              borderRadius: "16px",
            }}
            className="cross__chain__steps"
          >
            <Grid item xs={8}>
              <Typography
                sx={{
                  fontSize: "24px",

                  letterSpacing: "0",
                }}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                }}
              >
                How it works
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontSize: "25px",
                  lineHeight: "125%",
                  letterSpacing: "0",
                }}
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <AddCircleOutlineOutlinedIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => setBridgeMapModalOpen(true)}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default Bridge;
